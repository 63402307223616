<template>
  <VRow 
    id="lockoutPage"
    :style="{
      padding: $isUserAdmin ? '10px 44px' : '40px 50px',
    }"
  >
    <VOverlay :value="dialogs.selectTeam" />
    <VCol 
      cols="12" 
      class="text-center"
    >
      <img
        height="70px"
        :src="WeThriveLogo"
      >
      <h1 
        class="font-weight-black mt-4 px-1"
        style="font-size: 36px; line-height: 42px;"
      >
        {{ title }}
      </h1>
    </VCol>
    <VCol cols="12">
      <span 
        class="text-h6 font-weight-regular"
        style="color: #808080; line-height: 27px;"
      >
        {{ description }}
      </span>
    </VCol>
    <VCol 
      v-if="$isTeamOwner && $team.trialEnded && !$team.subscription_plan"
      cols="12" 
      class="pt-0"
    >
      <p
        v-for="(feature, index) in features"
        class="text-subtitle-1 mb-2"
        :key="index"
      >
        <VIcon 
          color="info" 
          size="20px"
          class="pr-2 mb-1"
        >
        mdi-check
        </VIcon>
        {{ feature }}
      </p>
    </VCol>
    <VCol 
      v-if="$isTeamOwner"
      cols="12"
      class="text-center"
    >
      <p 
        v-if="$team.trialEnded && !$team.subscription_plan" 
        class="text-h5 font-weight-black mb-5 mt-n4"
      >
        Plans start at $96/month
      </p>
      <VBtn
        block
        color="primary"
        height="54px"
        class="black--text"
        style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
        @click="$router.push({ name: 'billing' })"
      >
        <span 
          class="subtitle-1"
          style="font-weight: 600; color: #333333;"
        >
          View Plan Options
        </span>
      </VBtn>
    </VCol>
    <VCol
      cols="12"
      class="text-center"
    >
      <span 
        class="link body-1"
        style="color: #000000 !important;"
        @click="$bus.$emit('toggleIntercom')"
      >
        {{ $isTeamOwner ? "Not sure? Contact our customer support team for more info." : "Questions? Contact customer support" }}
      </span>
      <br>
      <span class="body-1">
        or
      </span>
      <br>
      <span 
        class="link body-1"
        style="color: #000000 !important;"
        @click="onToggleTeamsDialog"
      >
        Switch to another Team Workspace
      </span>
    </VCol>
    <SelectTeam
      hide-pending-invitations
      :select-team="dialogs.selectTeam"
      @close="onToggleTeamsDialog"
    />
  </VRow>
</template>
<script>
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import SelectTeam from "@/components/Organization/SelectTeam";
export default {
  name: "Lockout",
  components: {
    SelectTeam,
  },
  data() {
    return {
      WeThriveLogo,
      features: [
        "Central hub for participants and stakeholders",
        "Participant profiles",
        "Survey and data collection",
        "Application management and tracking",
        "Intake and registration",
        "Content library and storage",
        "Unlimited courses",
        "Quizzes and assessments",
        "Impact tracking and analytics",
        "Chat and messaging",
      ],
      dialogs: {
        selectTeam: false,
      },
    };
  },
  computed: {
    title() {
      if(this.$isTeamOwner) {
        return this.$team.trialEnded && !this.$team.subscription_plan ? "Your Team’s Free Trial Has Ended" : "Your Plan has Expired";
      }
      return "Sorry, this Team is Currently Inactive";
    },
    description() {
      if(this.$isTeamOwner) {
        return this.$team.trialEnded && !this.$team.subscription_plan 
          ? "We hope you enjoyed your 30-day free trial and got a taste of what WeThrive has to offer. Continue managing programs and scaling you team’s impact with these awesome features:" 
          : "This Team Workspace is no longer active. To access the Hubs, content, and messages for this Team, please click below view plan options and reactivate your Team account.";
      }
      return `This ${this.featureName('Program', 'singularize')} belongs to a workspace that is currently on hiatus. Please contact the Team admin to view this ${this.featureName('Program', 'singularize')}.`;
    },
  },
  methods: {
    onToggleTeamsDialog() {
      this.dialogs.selectTeam = !this.dialogs.selectTeam;
      if (
        !this.dialogs.selectTeam &&
        (this.$route.hash === "#/teams" || this.$route.hash === "#teams")
      ) {
        const newRoute = {
          ...this.$route,
          ...{
            hash: "",
          },
        };
        this.$router.replace(newRoute);
      }
    },
  },
}
</script>
<style lang="scss">
#lockoutPage {
  .teamMenuActivator {
    position: fixed; 
    left: 36px; 
    top: 10px;
  }
  #select-team {
    left: 36px;
  }
}
</style>